import React from 'react';
import { Button, utils } from '@makeship/core';
import StyledLink from '@makeship/core/lib/components/Link';
import styled from 'styled-components';
import config from '../../../../../../config.json';
import { scrollToVariants } from '../../commonFunctions';
import { trackSubscribeButtonClicked } from '../../../../../utils/analytics';
import { getProductFromContext } from '../../../../../context/product';
import { useStore } from '../../../../../store';

const { elements } = config;

const PledgeButtonBlocker = styled.div`
  position: absolute;
  width: 100%;
  height: 52px;
  cursor: pointer;
  border-radius: 8px;
`;

const AddToCartButton = styled(Button.Primary)<{ uiDisabled: boolean }>`
  width: 100%;
  background-color: ${({ uiDisabled, theme }) =>
    uiDisabled ? theme.colors.neutral4 : theme.colors.primary} !important;

  &:hover {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primaryDark} !important;
  }

  &:focus-visible {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primaryDark} !important;
  }

  &:focus-visible:after {
    border: 2px solid ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.neutral7} !important;
  }

  &:active {
    background-color: ${({ uiDisabled, theme }) => !uiDisabled && theme.colors.primary} !important;
  }
`;

const SubscribeToUpdatesButton = styled(StyledLink.Primary)`
  margin-top: 18px;
  margin-bottom: 32px;
`;

const SubscribeToUpdatesWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

type AddToCartProps = {
  shoppingCartRef: React.RefObject<HTMLDivElement>;
  isClothingWithSizes: boolean;
  hasSizeSelected: boolean;
  setError: (error: string | undefined) => void;
  setIsModalOpen: (isOpen: boolean) => void;
};

const AddToCart: React.FC<AddToCartProps> = ({
  shoppingCartRef,
  isClothingWithSizes,
  hasSizeSelected,
  setError,
  setIsModalOpen,
}: AddToCartProps) => {
  const product = getProductFromContext();
  const { state } = useStore();

  return (
    <>
      <div ref={shoppingCartRef} />
      <AddToCartWrapper>
        {isClothingWithSizes && !hasSizeSelected && (
          <PledgeButtonBlocker
            onClick={() => {
              scrollToVariants();
              setError('Please select a size!');
            }}
          />
        )}
        <AddToCartButton
          data-testid="static-pledge-button"
          id={elements.pledgeButton}
          uiDisabled={false}
          data-native-pre-order-btn="Pledge $2 Today"
        >
          Pledge $2 Today
        </AddToCartButton>
      </AddToCartWrapper>
      <SubscribeToUpdatesWrapper>
        <SubscribeToUpdatesButton
          onClick={() => {
            setIsModalOpen(true);
            trackSubscribeButtonClicked(product, state.user, 'No Thanks, But Send Me Updates');
          }}
        >
          No Thanks, But Send Me Updates
        </SubscribeToUpdatesButton>
      </SubscribeToUpdatesWrapper>
    </>
  );
};

export default AddToCart;
