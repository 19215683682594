import React, { useEffect, useRef, useState } from 'react';
import { ProgressBar } from '@makeship/core';
import styled from 'styled-components';
import { getProgressBarProps, getStage, parseMetafields } from '../../../../utils/product';
import { getProductFromContext } from '../../../../context/product';
import { Caption, P2, S2 } from '../../../Typography';
import { ProductStage } from '../../../../types/common';
import { ReactComponent as Info } from '../../../../../public/assets/icons/info-icon-1.svg';
import { formatNumberStringWithComma } from '../../../../utils/accounting';

const InfoIcon = styled(Info)`
  margin-left: 8px;
  vertical-align: text-top;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ProgressBarWrapper = styled.div<{ isSuccessfulPetition: boolean }>`
  margin-bottom: ${({ isSuccessfulPetition }) => (isSuccessfulPetition ? '36px' : '16px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 38px;
`;

const ProgressRow = styled.div<{ flexDirection?: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

const FundingStats = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const TooltipText = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
  & > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral6};
  right: 0;
  bottom: 26px;
  width: 300px;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
`;

type PetitionProgressBarProps = {
  moq: number;
  totalSold: number;
};

const PetitionProgressBar: React.FC<PetitionProgressBarProps> = ({ moq, totalSold }: PetitionProgressBarProps) => {
  const product = getProductFromContext();
  const stage = getStage(product.tags);
  const metafields = parseMetafields(product.metafields);
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const isOnProductCard = false;
  const { percentage, funded, height } = getProgressBarProps(product, isOnProductCard, totalSold);

  const handleClick = (e: MouseEvent) => {
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      setIsTooltipClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const getToolTipText = (): string =>
    new Map([
      [
        ProductStage.Petition,
        'When a petition reaches its goal, the Creator will design a real prototype and launch a 3 week campaign on Makeship. You can still make a pledge if the goal is reached to show your interest in the concept.',
      ],
      [ProductStage.PetitionFailed, 'The petition did not reach its goal. All pledges will be refunded.'],
      [
        ProductStage.PetitionSuccess,
        'The petition reached its goal! The Creator will design a real prototype and launch a 3 week campaign on Makeship.',
      ],
    ]).get(stage) || '';

  // Uses the frozen totalSold metafield on successful petitions
  const getSupportersCount = (): number => {
    if (stage === ProductStage.PetitionSuccess && metafields.totalsold) {
      // get totalSold metafield
      return metafields.totalsold;
    }

    return totalSold;
  };

  const getPercentFundedComponent = (): React.ReactNode => (
    <>
      {stage === ProductStage.PetitionFailed ? (
        <Caption>Goal not reached</Caption>
      ) : (
        <Caption>{formatNumberStringWithComma(Math.floor((getSupportersCount() / moq) * 100))}% of Goal</Caption>
      )}

      <InfoIconWrapper ref={ref} onClick={() => setIsTooltipClicked(!isTooltipClicked)}>
        <InfoIcon />
        {isTooltipClicked && (
          <Tooltip>
            <TooltipText>{getToolTipText()}</TooltipText>
          </Tooltip>
        )}
      </InfoIconWrapper>
    </>
  );

  return (
    <ProgressBarWrapper isSuccessfulPetition={stage === ProductStage.PetitionSuccess}>
      <ProgressBar percentage={percentage} funded={funded} height={height} />
      <ProgressRow data-testid="petition-progress-row">
        {stage !== ProductStage.PetitionFailed && (
          <S2>{`${formatNumberStringWithComma(getSupportersCount())} supporters`}</S2>
        )}
        <FundingStats data-testid="funded-stats">{getPercentFundedComponent()}</FundingStats>
      </ProgressRow>
    </ProgressBarWrapper>
  );
};

export default PetitionProgressBar;
